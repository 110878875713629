// import tarteaucitron from "../js/vendors/tarteaucitron/tarteaucitron.js";

// Tarte au citron

tarteaucitron.init({
  googleConsentMode: true,
  privacyUrl: "content/2-mentions-legales" /* Privacy policy url */,

  hashtag: "#tarteaucitron" /* Open the panel with this hashtag */,
  cookieName: "tarteaucitron" /* Cookie name */,

  orientation: "bottom" /* Banner position (top - bottom) */,

  groupServices: true /* Group services by category */,

  showAlertSmall: false /* Show the small banner on bottom right */,
  cookieslist: false /* Show the cookie list */,

  closePopup: false /* Show a close X on the banner */,

  showIcon: false /* Show cookie icon to manage cookies */,
  //"iconSrc": ", /* Optionnal: URL or base64 encoded image */
  iconPosition:
    "BottomRight" /* BottomRight, BottomLeft, TopRight and TopLeft */,

  adblocker: false /* Show a Warning if an adblocker is detected */,

  DenyAllCta: true /* Show the deny all button */,
  AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
  highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,

  handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,

  removeCredit: true /* Remove credit link */,
  moreInfoLink: true /* Show more info link */,

  useExternalCss: true /* If false, the tarteaucitron.css file will be loaded */,
  useExternalJs: false /* If false, the tarteaucitron.js file will be loaded */,

  //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

  readmoreLink: " /* Change the default readmore link */",
  mandatory: true /* Show a message about mandatory cookies */,
});
(tarteaucitron.job = tarteaucitron.job || []).push("gcmanalyticsstorage");
(tarteaucitron.job = tarteaucitron.job || []).push("gcmadstorage");
(tarteaucitron.job = tarteaucitron.job || []).push("gcmadsuserdata");
(tarteaucitron.job = tarteaucitron.job || []).push("gcmpersonalization");
(tarteaucitron.job = tarteaucitron.job || []).push("gcmfunctionality");
(tarteaucitron.job = tarteaucitron.job || []).push("gcmsecurity");

tarteaucitron.events.load = function () {
  // Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-KMWMHTJ");
  // End Google Tag Manager

  // Google Tag Manager (noscript)
  const gtmlNoScript = document.createElement("noscript");
  const gtmNoScriptIframe = document.createElement("iframe");
  gtmNoScriptIframe.src =
    "https://www.googletagmanager.com/ns.html?id=GTM-KMWMHTJ";
  gtmNoScriptIframe.width = "0";
  gtmNoScriptIframe.height = "0";
  gtmNoScriptIframe.style = "display:none;visibility:hidden";

  gtmlNoScript.append(gtmNoScriptIframe);
  document.addEventListener("DOMContentLoaded", function () {
    document.body.insertBefore(gtmlNoScript, document.body.firstChild);
  });
  // End Google Tag Manager (noscript)
};

var cookieBtn = $(".cookies-link");
if (cookieBtn.length > 0) {
  cookieBtn.on("click", function (e) {
    e.preventDefault();
    console.log("cookies!");
    tarteaucitron.userInterface.openPanel();
  });
}
